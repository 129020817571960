//Hides the total count button
li.page-item.page-last {
  display: none;
}

/**
 * Hides table columns whose data-class is set to "hidden". This can't be done
 * through bootstrap-table options since that drops the column out of the dom
 * https://stackoverflow.com/questions/49244153/bootstrap-table-how-to-hide-a-column-without-deleting-it-from-the-dom
 */
th.hidden, td.hidden {
  display: none;
  visibility: hidden;
}

#devices-table-load-more-button {
  float: right;
}