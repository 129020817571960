.tom-select .scientific {
  font-weight: normal;
  opacity: 0.3;
  margin: 0 0 0 2px;
}

.tom-select .optgroup-header {
  font-weight: normal;
  opacity: 0.3;
  margin: 0 0 0 2px;
}