/* Place all the styles related to the locations controller here.
 They will automatically be included in application.css.
 You can use Sass (SCSS) here: http://sass-lang.com/ */
.image {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}

.img-container {
  width: 750px;
  overflow: hidden;
  position: relative;
}

#locationForm {
  padding-left: 1em;
}

.location_index.list-group-item:hover {
  background-color: lightgrey;
}

.existing_device {
  cursor: pointer;
}

.area-select-container {
  width: 300px;
}

#tableHolder, .update-location-form {
  padding: 20px;
}