#applyFiltersButton {
  float: right;
}

#filtersCollapse {
  padding: 7px;
}

#filtersAccordion {
  width: auto;
  margin: 10px 1%;
}