@import "bootstrap/scss/bootstrap.scss";
@import "auth.scss";
@import "add_device.scss";
@import "manage_devices.scss";
@import "home.scss";
@import "reports.scss";
@import "teams.scss";
@import "settings.scss";
@import "sessions.scss";
@import "qr_codes.scss";
@import "walkthrough.scss";
@import "cascading_lists.scss";
@import "areas.scss";
@import "device_settings.scss";
@import "create_organization.scss";
@import "organization.scss";
@import "error_messages.scss";
@import "table.scss";
@import "filters.scss";
@import "tom-select/dist/css/tom-select.min.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "bootstrap-table/dist/bootstrap-table.min.css";
@import "bootstrap-table/dist/extensions/sticky-header/bootstrap-table-sticky-header.min";
@import "@claviska/jquery-minicolors/jquery.minicolors.css";
@import "@fonticonpicker/fonticonpicker/dist/css/base/jquery.fonticonpicker.min.css";
@import "@fonticonpicker/fonticonpicker/dist/css/themes/grey-theme/jquery.fonticonpicker.grey.min.css";
@import "font-awesome/css/font-awesome.min.css";
@import 'material-icons/iconfont/material-icons.css';
@import "custom_utilities.scss";
@import "selects";